import { extractCnjLawsuitNumbers } from '../extract-cnj-lawsuit-numbers/extract-cnj-lawsuit-numbers'

/**
 * Function that extracts the first CNJ lawsuit number found in a string.
 *
 * @param text The string that contains the CNJ lawsuit number.
 * @returns Returns the extracted CNJ lawsuit number or
 * null if none is found.
 */
export function extractCnjLawsuitNumber(text: string): string | null {
  const [extractedLawsuitNumber] = extractCnjLawsuitNumbers(text) || []

  return extractedLawsuitNumber || null
}
