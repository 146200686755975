import { assignSubComponents } from '@farol-ds/react/utilities/internal'

import FooterBottom from './footer-bottom'
import FooterCopyright from './footer-copyright'
import FooterHead from './footer-head'
import FooterMenu from './footer-menu'
import FooterRoot from './footer-root'
import FooterSocial from './footer-social'

export const Footer = assignSubComponents('Footer', FooterRoot, {
  Head: FooterHead,
  Bottom: FooterBottom,
  Menu: FooterMenu,
  Copyright: FooterCopyright,
  Social: FooterSocial,
})

export * from './footer-bottom'
export * from './footer-copyright'
export * from './footer-head'
export * from './footer-menu'
export * from './footer-root'
export * from './footer-social'
