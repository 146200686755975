import { BodyText, Caption, Col, Link, List, Row, Slot, Slottable } from '@farol-ds/react'
import { forwardRef, HTMLAttributes } from 'react'

import { defaultFooterMenuColumns } from './constants'
import styles from './footer-menu.module.scss'
import { FooterMenuColumn } from './types'

export type FooterMenuElement = HTMLDivElement
export type FooterMenuProps = Omit<HTMLAttributes<FooterMenuElement>, 'children'> & {
  asChild?: boolean
  columns?: FooterMenuColumn[]
}

const FooterMenu = forwardRef<FooterMenuElement, FooterMenuProps>(function FooterMenu(
  props,
  forwardedRef
) {
  const { asChild, columns = defaultFooterMenuColumns, ...rest } = props

  const Component = asChild ? Slot : 'div'

  return (
    <Row asChild>
      <Component {...rest} ref={forwardedRef}>
        <Slottable>
          {columns.map((column, index) => (
            <Col xs={12} sm={6} key={index} className={styles.col} asChild>
              <section>
                <BodyText size="md" className={styles.title} asChild>
                  <span>{column.label}</span>
                </BodyText>

                <Caption asChild>
                  <List unstyled className={styles.list}>
                    {column.items.map((item, index) => (
                      <List.Item key={index}>
                        <Link href={item.href} type="ghost" target="_blank">
                          {item.label}
                        </Link>
                        {index < column.items.length - 1 && (
                          <span className={styles.sep} aria-hidden="true" role="separator">
                            •
                          </span>
                        )}
                      </List.Item>
                    ))}
                  </List>
                </Caption>
              </section>
            </Col>
          ))}
        </Slottable>
      </Component>
    </Row>
  )
})

export default FooterMenu
