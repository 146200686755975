import { InstagramIcon, LinkedinIcon, XIcon, YoutubeIcon } from '@farol-ds/react'

import { FooterMenuColumn, FooterSocialItem } from './types'

export const defaultFooterSocialItems: readonly FooterSocialItem[] = [
  {
    label: 'Instagram',
    href: 'https://www.instagram.com/jusbrasil',
    icon: <InstagramIcon />,
  },
  {
    label: 'X / Twitter',
    href: 'https://twitter.com/jusbrasil',
    icon: <XIcon />,
  },
  {
    label: 'LinkedIn',
    href: 'https://www.linkedin.com/company/jusbrasil',
    icon: <LinkedinIcon />,
  },
  {
    label: 'YouTube',
    href: 'https://www.youtube.com/jusbrasil',
    icon: <YoutubeIcon />,
  },
] as const

export const defaultFooterMenuColumns: readonly FooterMenuColumn[] = [
  {
    label: 'Para todas as pessoas',
    items: [
      {
        label: 'Consulta Processual',
        href: 'https://www.jusbrasil.com.br/consulta-processual/',
      },
      {
        label: 'Artigos',
        href: 'https://www.jusbrasil.com.br/artigos/',
      },
      {
        label: 'Notícias',
        href: 'https://www.jusbrasil.com.br/noticias/',
      },
    ],
  },
  {
    label: 'Para profissionais',
    items: [
      {
        label: 'Jurisprudência',
        href: 'https://www.jusbrasil.com.br/jurisprudencia/',
      },
      {
        label: 'Doutrina',
        href: 'https://www.jusbrasil.com.br/doutrina/',
      },
      {
        label: 'Diários Oficiais',
        href: 'https://www.jusbrasil.com.br/diarios/',
      },
      {
        label: 'Peças Processuais',
        href: 'https://www.jusbrasil.com.br/pecas/',
      },
      {
        label: 'Modelos',
        href: 'https://www.jusbrasil.com.br/modelos-pecas/',
      },
      {
        label: 'Legislação',
        href: 'https://www.jusbrasil.com.br/legislacao/',
      },
    ],
  },
  {
    label: 'Para empresas',
    items: [
      {
        label: 'Jusbrasil Soluções',
        href: 'https://solucoes.jusbrasil.com.br',
      },
      {
        label: 'Departamentos jurídicos',
        href: 'https://solucoes.jusbrasil.com.br/departamentos-juridicos',
      },
      {
        label: 'Empresas',
        href: 'https://solucoes.jusbrasil.com.br/empresas',
      },
      {
        label: 'Escritórios de advocacia',
        href: 'https://solucoes.jusbrasil.com.br/escritorios-advocacia',
      },
      {
        label: 'API Jusbrasil',
        href: 'https://insight.jusbrasil.com.br',
      },
    ],
  },
  {
    label: 'Jusbrasil',
    items: [
      {
        label: 'Sobre nós',
        href: 'https://sobre.jusbrasil.com.br',
      },
      {
        label: 'Ajuda',
        href: 'https://ajuda.jusbrasil.com.br',
      },
      {
        label: 'Newsletter',
        href: 'https://www.jusbrasil.com.br/newsletter/',
      },
      {
        label: 'Termos de Uso',
        href: 'https://www.jusbrasil.com.br/termos-de-uso',
      },
      {
        label: 'Política de Privacidade',
        href: 'https://www.jusbrasil.com.br/politica-de-privacidade',
      },
      {
        label: 'Central de Privacidade',
        href: 'https://www.jusbrasil.com.br/privacidade',
      },
      {
        label: 'Denúncias',
        href: 'https://jusbrasil.semfraude.online',
      },
    ],
  },
] as const
