import { Container, Slot, Slottable } from '@farol-ds/react'
import classNames from 'classnames'
import { forwardRef, HTMLAttributes } from 'react'

import styles from './footer-bottom.module.scss'

export type FooterBottomElement = HTMLDivElement
export type FooterBottomProps = HTMLAttributes<FooterBottomElement> & {
  asChild?: boolean
}

const FooterBottom = forwardRef<FooterBottomElement, FooterBottomProps>(function FooterBottom(
  props,
  forwardedRef
) {
  const { children, asChild, className, ...rest } = props
  const Component = asChild ? Slot : 'div'
  const classes = classNames(styles.root, className)
  return (
    <div className={styles.container}>
      <Container asChild>
        <Component {...rest} className={classes} ref={forwardedRef}>
          <Slottable>{children}</Slottable>
        </Component>
      </Container>
    </div>
  )
})

export default FooterBottom
