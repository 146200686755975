import { cnjLawsuitNumberRegExp } from '../../regexp'
import { LawsuitNumber } from '../lawsuit-number/lawsuit-number'

function formatCnj(number: string): string | null {
  const cnj = new LawsuitNumber(number)
  return cnj.isValid ? cnj.toString() : null
}

/**
 * Function that extracts all CNJ lawsuit numbers found in a string.
 *
 * @param text String that contains one or more CNJ lawsuit numbers.
 * @returns An array of the CNJ lawsuit numbers found in the string.
 * If none is found, returns null.
 */
export function extractCnjLawsuitNumbers(text: string): string[] | null {
  const extractedLawsuitNumbers: RegExpMatchArray | null = text.match(cnjLawsuitNumberRegExp)

  if (!extractedLawsuitNumbers) {
    return null
  }

  const lawsuitNumbers: string[] = extractedLawsuitNumbers
    .map(formatCnj)
    .filter((n): n is string => !!n)

  return lawsuitNumbers
}
