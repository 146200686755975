import { Brand, Caption, Slot, Slottable } from '@farol-ds/react'
import classNames from 'classnames'
import { forwardRef, HTMLAttributes } from 'react'

import styles from './footer-copyright.module.scss'

export type FooterCopyrightElement = HTMLDivElement
export type FooterCopyrightProps = Omit<HTMLAttributes<FooterCopyrightElement>, 'children'> & {
  asChild?: boolean
  currentYear?: number
}

const DEFAULT_YEAR = process.env.NODE_ENV === 'test' ? 2024 : new Date().getFullYear()

const FooterCopyright = forwardRef<FooterCopyrightElement, FooterCopyrightProps>(
  function FooterCopyright(props, forwardedRef) {
    const { asChild, className, currentYear = DEFAULT_YEAR, ...rest } = props

    const Component = asChild ? Slot : 'div'
    const classes = classNames(styles.root, className)

    return (
      <Component {...rest} className={classes} ref={forwardedRef}>
        <Slottable>
          <Brand
            version="positive"
            onlySymbol={true}
            width={24}
            height={24}
            className={styles.brand}
          />
          <Caption className={styles.copyText}>
            A sua principal fonte de informação jurídica - © {currentYear} Jusbrasil. Todos os
            direitos reservados.
          </Caption>
        </Slottable>
      </Component>
    )
  }
)

export default FooterCopyright
