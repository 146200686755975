type NullableConnection<Node = unknown> =
  | {
      readonly edges: ReadonlyArray<{
        readonly node: Node | null
      } | null> | null
    }
  | null
  | undefined

export type NullableConnectionNode<Connection extends NullableConnection> = NonNullable<
  NonNullable<NonNullable<NonNullable<Connection>['edges']>[number]>['node']
>

export function nullableConnectionNodes<C extends NullableConnection>(connection: C) {
  return (
    connection?.edges
      ?.map((e) => e?.node)
      .filter((n): n is NullableConnectionNode<C> => n !== null && n !== undefined) || []
  )
}
