import { cnjLawsuitNumberRegExp } from '../../regexp'
import { LawsuitNumberOptions } from '../../types'
import iso7064 from '../iso-7064'

const SIZES = [7, 2, 4, 1, 2, 4]
const LAWSUIT_NUMBER_LENGTH = 20

const padZeroNumber = (s: string, n: number) => s.padStart(n, '0')
const padLawsuitNumber = (s: string) => padZeroNumber(s, LAWSUIT_NUMBER_LENGTH)

const parseLawsuitNumber = (number: string, padWithZeros = false) => {
  let result = number.trim()
  result = result.replace(cnjLawsuitNumberRegExp, '$1$2$3$4$5$6')
  if (padWithZeros) {
    result = padLawsuitNumber(result)
  }
  const parts = result.split('')
  return SIZES.map((length) => parts.splice(0, length).join(''))
}

export class LawsuitNumber {
  public readonly isValid: boolean
  public readonly isCNJFormat: boolean

  private dv: string | undefined
  private proc: string | undefined
  private year: string | undefined
  private justice: string | undefined
  private number: string | undefined
  private court: string | undefined
  private invalidReason: string

  /**
   * Create a object that validates a CNJ lawsuit number.
   * @param cnj The string that contains the CNJ lawsuit number
   * @param options Options to configure the validation.
   */
  constructor(cnj: string, options?: LawsuitNumberOptions) {
    const padWithZeros = options?.padWithZeros || true
    const validateDigit = options?.validateDigit || false

    this.invalidReason = 'Lawsuit Number is Invalid'
    this.isCNJFormat = false

    /**
     * This works with dirt strings. The error
     * only happens later when trying to compute
     * the iso7064 of the CNJ lawsuit number.
     * Is this behavior encouraged or we should better
     * test the validity of the string here?
     *
     * @raphzandrade
     */
    this.isValid = cnjLawsuitNumberRegExp.test(cnj)

    if (this.isValid) {
      const parsedLawsuitNumber = parseLawsuitNumber(cnj, padWithZeros)
      const [proc, dv, year, justice, number, court] = parsedLawsuitNumber
      const firstStep = iso7064.compute(proc).toString()
      const secondStep = iso7064.compute(firstStep + year + justice + number).toString()
      const thirdStep = iso7064.compute(`${secondStep + court}00`)

      const ndv = 98 - (thirdStep % 97)
      this.dv = padZeroNumber(ndv.toString(), 2)

      const currentYear = new Date().getFullYear()

      if (Number(year) <= 1900 || Number(year) > currentYear) {
        this.isValid = false
      }

      if (dv !== null && dv !== this.dv) {
        if (validateDigit) {
          this.isValid = false
          const expectedDigit = this.dv
          this.invalidReason = `Checksum not correct - CNJ number is invalid. Expected is ${expectedDigit}, received ${dv}.`
        }
        this.dv = dv
      }

      this.proc = proc
      this.year = year
      this.justice = justice
      this.number = number
      this.court = court
      this.isCNJFormat = true
    }
  }

  /**
   *
   * @returns Throws an error if the CNJ lawsuit number is invalid.
   */
  public validate(): void {
    if (this.isValid) return

    throw new Error(this.invalidReason)
  }

  /**
   * @returns Returns the formatted CNJ lawsuit number.
   */
  public toString(): string {
    this.validate()
    return `${this.proc}-${this.dv}.${this.year}.${this.justice}.${this.number}.${this.court}`
  }

  /**
   * @returns Returns the raw CNJ lawsuit number.
   */
  public raw(): string {
    this.validate()
    return `${this.proc}${this.dv}${this.year}${this.justice}${this.number}${this.court}`
  }
}
