import { IconButton, Slot, Slottable } from '@farol-ds/react'
import classNames from 'classnames'
import { forwardRef, HTMLAttributes } from 'react'

import { defaultFooterSocialItems } from './constants'
import styles from './footer-social.module.scss'
import { FooterSocialItem } from './types'

export type FooterSocialElement = HTMLDivElement
export type FooterSocialProps = Omit<HTMLAttributes<FooterSocialElement>, 'children'> & {
  asChild?: boolean
  items?: FooterSocialItem[]
}

const FooterSocial = forwardRef<FooterSocialElement, FooterSocialProps>(function FooterSocial(
  props,
  forwardedRef
) {
  const { asChild, items = defaultFooterSocialItems, className, ...rest } = props
  const Component = asChild ? Slot : 'div'
  const classes = classNames(styles.root, className)
  return (
    <Component {...rest} className={classes} ref={forwardedRef}>
      <Slottable>
        {items.map((item, index) => (
          <IconButton
            key={index}
            kind="plain"
            label={item.label}
            aria-label={item.label}
            size="md"
            asChild
          >
            <a href={item.href} target="_blank" rel="noreferrer">
              {item.icon}
            </a>
          </IconButton>
        ))}
      </Slottable>
    </Component>
  )
})

export default FooterSocial
