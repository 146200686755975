// base implementation https://github.com/EDumdum/iso-7064-js

const CHARCODE_A = 'A'.charCodeAt(0)
const CHARCODE_0 = '0'.charCodeAt(0)

const FORMAT = /^[0-9A-Z]{1,}$/

function stringifyInput(rawValue: unknown, valueName = 'rawValue'): string {
  if (rawValue === null || rawValue === undefined) {
    throw new Error(`Expecting ${valueName} of type 'string', found: rawValue}'`)
  }

  if (typeof rawValue !== 'string') {
    throw new Error(`Expecting ${valueName} of type 'string', found: '${typeof rawValue}'`)
  }

  return rawValue
}

function mod97(value: string): number {
  let buffer = 0

  for (let i = 0; i < value.length; ++i) {
    const charCode = value.charCodeAt(i)

    buffer =
      charCode +
      (charCode >= CHARCODE_A ? buffer * 100 - CHARCODE_A + 10 : buffer * 10 - CHARCODE_0)

    if (buffer > 1000000) {
      buffer %= 97
    }
  }

  return buffer % 97
}

const iso7064 = {
  compute(rawValue: string): number {
    const value = stringifyInput(rawValue)

    if (!value.match(FORMAT)) {
      throw new Error(`Invalid data format; expecting: '${FORMAT}', found: '${value}'`)
    }

    return mod97(value)
  },

  computeWithoutCheck(rawValue: string): number {
    return mod97(rawValue)
  },
}

export default iso7064
