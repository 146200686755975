import classNames from 'classnames'
import { forwardRef, HTMLAttributes } from 'react'

import FooterBottom from './footer-bottom'
import FooterCopyright from './footer-copyright'
import FooterHead from './footer-head'
import FooterMenu from './footer-menu'
import styles from './footer-root.module.scss'
import FooterSocial from './footer-social'

export type FooterRootElement = HTMLDivElement
export type FooterRootProps = HTMLAttributes<FooterRootElement>

const FooterRoot = forwardRef<FooterRootElement, FooterRootProps>(function FooterRoot(
  props,
  forwardedRef
) {
  const { children, className, ...rest } = props

  const classes = classNames(styles.root, className)
  return (
    <footer {...rest} className={classes} ref={forwardedRef}>
      {children ?? (
        <>
          <FooterHead>
            <FooterMenu />
          </FooterHead>
          <FooterBottom>
            <FooterCopyright />
            <FooterSocial />
          </FooterBottom>
        </>
      )}
    </footer>
  )
})

export default FooterRoot
